import { ReacteurIconsPaths } from '@lereacteur/icons';
import { useState, useCallback, useLayoutEffect } from 'democrat';
import { useShallowMemo } from '@lereacteur/apollo-common/dist/hooks/useShallowMemo';

const loadIcons = () => import('@lereacteur/icons').then((res) => res.ICONS_PATHS);

export interface IconsSliceState {
  load: () => Promise<void>;
  requested: boolean;
  ICONS_PATHS: null | ReacteurIconsPaths;
}

export function IconsSlice(): IconsSliceState {
  const [requested, setRequested] = useState<boolean>(false);
  const [result, setResult] = useState<null | ReacteurIconsPaths>(null);

  const load = useCallback(async () => {
    setRequested(true);
  }, []);

  useLayoutEffect(() => {
    if (requested && !result) {
      loadIcons().then((ICONS_PATHS) => {
        setResult(ICONS_PATHS);
      });
    }
  }, [requested, result]);

  return useShallowMemo({
    load,
    requested,
    ICONS_PATHS: result,
  });
}

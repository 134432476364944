import React from 'react';
import { getRoute } from 'routes';
import { useLocation } from 'logic/hooks/useLocation';
import { useCore } from 'logic/hooks/useCore';
import { AppWrapper } from 'views/AppWrapper';

interface RouterProps {}

export const Router: React.FC<RouterProps> = React.memo<RouterProps>(function Router() {
  const location = useLocation();
  const { AsyncViews } = useCore();

  const routeResult = getRoute(location.pathname, AsyncViews);
  const route = routeResult.exec();
  const element = route();

  return <AppWrapper content={element} />;
});

import { nni } from '@lereacteur/apollo-common/dist/logic/Invariant';

export interface IEnvs {
  IS_DEV: boolean;
  REACT_APP_REACTEUR_API: string;
  REACT_APP_PUSH_WEBSOCKET: string;
  REACT_APP_AUTO_OPEN_DEVTOOL: boolean;
  REACT_APP_ADMIN_APP: string;
  REACT_APP_STUDENT_APP: string;
}

export const Envs: IEnvs = {
  IS_DEV: process.env.NODE_ENV !== 'production',
  REACT_APP_AUTO_OPEN_DEVTOOL: Boolean(process.env.REACT_APP_AUTO_OPEN_DEVTOOL || false),
  REACT_APP_PUSH_WEBSOCKET: nni(process.env.REACT_APP_PUSH_WEBSOCKET),
  REACT_APP_REACTEUR_API: nni(process.env.REACT_APP_REACTEUR_API),
  REACT_APP_ADMIN_APP: nni(process.env.REACT_APP_ADMIN_APP),
  REACT_APP_STUDENT_APP: nni(process.env.REACT_APP_STUDENT_APP),
};

import React from 'react';
import { IconsContext } from './IconsContext';
import { Svg, Wrapper } from './elements';
import { IconsPaths, IconNames } from './types';

export interface IconProps<I extends IconsPaths> {
  icon: IconNames<I>;
  color?: string;
  strokeWidth?: number | string;
  iconSize?: number;
}

export interface IconsProviderProps<I extends IconsPaths> {
  children?: React.ReactNode;
  iconsPaths: I | null;
}

export function createIconComponent<I extends IconsPaths>() {
  const IconsProvider: React.FC<IconsProviderProps<I>> = React.memo(function IconsProvider({
    children,
    iconsPaths,
  }) {
    return <IconsContext.Provider value={iconsPaths}>{children}</IconsContext.Provider>;
  });

  const Icon: React.FC<IconProps<I>> = ({
    iconSize = 18,
    color = 'currentColor',
    icon,
    strokeWidth = '2',
  }) => {
    const iconsPaths: I = React.useContext(IconsContext) as any;
    const parts = iconsPaths && iconsPaths[icon];
    const elems = parts
      ? parts.map((d, i) => {
          if (typeof d === 'string') {
            return <path key={i} d={d} fillRule="evenodd" />;
          }
          if (Array.isArray(d)) {
            const [type, params] = d;
            if (type === 'rect') {
              const [x, y, width, height, rx, ry] = params;
              return <rect key={i} x={x} y={y} width={width} height={height} rx={rx} ry={ry} />;
            }
          }
          console.warn(`Unhandled icon part`);
          return <path key={i} fillRule="evenodd" />;
        })
      : [];

    return (
      <Wrapper style={{ width: iconSize, height: iconSize }}>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          strokeWidth={strokeWidth}
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {elems}
        </Svg>
      </Wrapper>
    );
  };

  return { Icon, IconsProvider };
}

import { AppAsyncViewName } from 'logic/async-views';
import queryString from 'query-string';
import { Chemin } from 'chemin';
import { Query } from '@lereacteur/apollo-common/dist/router/NavigationSlice';
import { ExtractMatch, RoutePattern } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { nni } from '@lereacteur/apollo-common/dist/logic/Invariant';
import { STUDENT_APP_ROUTES } from '@lereacteur/common/dist/constants/STUDENT_APP_ROUTES';
import { AppSliceState } from 'logic/slices/AppSlice';
import { SelectManager, Selector } from '@lereacteur/apollo-common/dist/connect/SelectManager';
import { createVoidResource } from '@lereacteur/apollo-common/dist/hooks/useResource';
import { User } from '@lereacteur/common/dist/routes/user';

export type Selectors = ReturnType<typeof createSelectors>;

export function createSelectors(selectManager: SelectManager) {
  function createAccessSelect<Input, Output>(
    access: (input: Input) => Output
  ): Selector<[Input], Output> {
    return (input) => selectManager.selectGlobal(access, () => access(input), [access(input)]);
  }

  function createSelectRoute<T>(route: Chemin<T>): Selector<[AppSliceState], T | false> {
    return (state: AppSliceState) => {
      const pathname = selectPathname(state);
      return selectManager.selectGlobal(route, () => route.matchExact(pathname), [pathname]);
    };
  }

  const selectLocation = createAccessSelect((state: AppSliceState) => state.location);
  const selectPathname = createAccessSelect((state: AppSliceState) => state.location.pathname);
  const selectRequested = createAccessSelect((state: AppSliceState) => state.navigation.requested);
  const selectNavigate = createAccessSelect((state: AppSliceState) => state.navigation.navigate);
  const selectCreateLinkProps = createAccessSelect(
    (state: AppSliceState) => state.navigation.createLinkProps
  );
  const selectShowUnsavedWarning = createAccessSelect(
    (state: AppSliceState) => state.unsavedWarningVisible
  );
  const selectUnsaved = createAccessSelect((state: AppSliceState) => state.unsaved.unsaved);
  const selectRegisterUnsaved = createAccessSelect(
    (state: AppSliceState) => state.unsaved.register
  );
  const selectCancelNavigation = createAccessSelect(
    (state: AppSliceState) => state.navigation.cancelRequestedLocation
  );
  const selectIgnoreUnsaved = createAccessSelect((state: AppSliceState) => state.unsaved.clearAll);
  const selectAsyncViews = createAccessSelect((state: AppSliceState) => state.asyncViews);
  const selectSearchRes = createAccessSelect((state: AppSliceState) => state.search);
  const selectAdminMode = createAccessSelect((state: AppSliceState) => state.adminMode);
  const selectSetAdminMode = createAccessSelect((state: AppSliceState) => state.setAdminMode);
  const selectHideOldSessions = createAccessSelect((state: AppSliceState) => state.hideOldSessions);
  const selectSetHideOldSessions = createAccessSelect((state: AppSliceState) => state.setHideOldSessions);
  const selectSetToken = createAccessSelect((state: AppSliceState) => state.setToken);
  const selectToken = createAccessSelect((state: AppSliceState) => state.token);
  const selectLogout = createAccessSelect((state: AppSliceState) => state.logout);

  const selectRequestedPathname: Selector<[AppSliceState], string | null> = (state) => {
    const requested = selectRequested(state);
    return selectManager.selectGlobal(
      'SELECTED_PATHNAME',
      () => (requested ? requested.location.pathname : null),
      [requested]
    );
  };

  const selectParsedQuery = (state: AppSliceState): Query => {
    const location = selectLocation(state);
    return selectManager.selectGlobal(
      'PARSED_QUERY',
      () => {
        return queryString.parse(location.search, {
          arrayFormat: 'none',
          decode: true,
          parseBooleans: false,
          parseNumbers: false,
        }) as any; // we force type because we know there are no arrays !
      },
      [location.search]
    );
  };

  const selectParsedQueryKey = (state: AppSliceState, key: string): string | null => {
    const query = selectParsedQuery(state);
    return query[key] || null;
  };

  const selectHomeRouteMatch = createSelectRoute(STUDENT_APP_ROUTES.home);
  const selectLoginRouteMatch = createSelectRoute(STUDENT_APP_ROUTES.login);
  const selectStudentCourseRouteMatch = createSelectRoute(STUDENT_APP_ROUTES.courseHome);

  const selectAllRoutesRequested: Selector<
    [AppSliceState],
    ExtractMatch<typeof STUDENT_APP_ROUTES> | null
  > = (state: AppSliceState) => {
    const pathname = selectRequestedPathname(state);
    return selectManager.selectGlobal(
      'ROUTES_REQUESTED',
      () => {
        return pathname ? RoutePattern.matchNestedRouteObject(STUDENT_APP_ROUTES, pathname) : null;
      },
      [pathname]
    );
  };

  const selectAllRoutesMatch: Selector<[AppSliceState], ExtractMatch<typeof STUDENT_APP_ROUTES>> = (
    state: AppSliceState
  ) => {
    const pathname = selectPathname(state);
    return selectManager.selectGlobal(
      STUDENT_APP_ROUTES,
      () => {
        return RoutePattern.matchNestedRouteObject(STUDENT_APP_ROUTES, pathname);
      },
      [pathname]
    );
  };

  const selectCourse = (state: AppSliceState, courseId: string) =>
    selectManager.select(() => {
      return state.courseMap.getOrVoid(courseId);
    }, [courseId, state.courseMap]);

  const selectSession = (state: AppSliceState, sessionId: string) =>
    selectManager.select(() => {
      return state.sessionMap.getOrVoid(sessionId);
    }, [sessionId, state.sessionMap]);

  const selectSessionTree = (state: AppSliceState, sessionId: string) =>
    selectManager.select(() => {
      return state.sessionTreeMap.getOrVoid(sessionId);
    }, [sessionId, state.sessionTreeMap]);

  const selectAtomRes = (state: AppSliceState, atomId: string) =>
    selectManager.select(() => {
      return state.atomMap.getOrVoid(atomId);
    }, [atomId, state.atomMap]);

  const selectMeAsUser = (state: AppSliceState) =>
    selectManager.select(() => {
      if (state.meAsUser) {
        return state.meAsUser;
      }
      return createVoidResource<User>('VoidMeAsUser', () => {
        console.warn(`Requesting Void resource`);
      });
    }, [state.meAsUser]);

  const selectAsyncViewState = (state: AppSliceState, view: AppAsyncViewName) =>
    selectManager.select(() => {
      return nni(state.asyncViews.state[view]);
    }, [view, state.asyncViews]);

  const selectMeUser = createAccessSelect((state: AppSliceState) => state.me);

  const selectIconsPaths = createAccessSelect((state: AppSliceState) => state.icons.ICONS_PATHS);

  return {
    selectAllRoutesMatch,
    selectAllRoutesRequested,
    selectAsyncViews,
    selectAsyncViewState,
    selectAtomRes,
    selectCancelNavigation,
    selectCourse,
    selectCreateLinkProps,
    selectHomeRouteMatch,
    selectIconsPaths,
    selectIgnoreUnsaved,
    selectLocation,
    selectLoginRouteMatch,
    selectMeUser,
    selectNavigate,
    selectParsedQuery,
    selectParsedQueryKey,
    selectPathname,
    selectRegisterUnsaved,
    selectRequested,
    selectRequestedPathname,
    selectSearchRes,
    selectSession,
    selectSessionTree,
    selectShowUnsavedWarning,
    selectStudentCourseRouteMatch,
    selectUnsaved,
    selectAdminMode,
    selectSetAdminMode,
    selectHideOldSessions,
    selectSetHideOldSessions,
    selectSetToken,
    selectToken,
    selectLogout,
    selectMeAsUser,
  } as const;
}

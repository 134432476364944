import { createResourceMapSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { CourseApi } from '@lereacteur/apollo-common/dist/api/CourseApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from '../hooks/useLatestRef';
import { Course } from '@lereacteur/common/dist/routes/course';

interface Props {
  courseApi: CourseApi;
  realtime: RealtimeClientManager;
}

export function CourseMapSlice({ courseApi, realtime }: Props) {
  const courses = useChildren(
    createResourceMapSlice<string, Course>({
      name: 'Course',
      fetchData: courseApi.getById,
    })
  );

  const coursesRef = useLatestRef(courses);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.course) {
        const res = coursesRef.current.resources.get(routes.course.id);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [coursesRef, realtime]);

  return courses;
}

import { useChildren, createElement, useLayoutEffect, useMemo } from 'democrat';
import { RouterSlice } from '@lereacteur/apollo-common/dist/router/RouterSlice';
import { NavigationSlice } from '@lereacteur/apollo-common/dist/router/NavigationSlice';
import { IconsSlice } from './IconsSlices';
import { useShallowMemo } from '@lereacteur/apollo-common/dist/hooks/useShallowMemo';
import { AuthApi } from '@lereacteur/apollo-common/dist/api/AuthApi';
import { MeResourceSlice } from '@lereacteur/apollo-common/dist/slices/MeResourceSlice';
import { AtomApi } from '@lereacteur/apollo-common/dist/api/AtomApi';
import { UnsavedSlice } from '@lereacteur/apollo-common/dist/slices/UnsavedSlice';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { TokenSlice } from '@lereacteur/apollo-common/dist/slices/TokenSlice';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { CourseApi } from '@lereacteur/apollo-common/dist/api/CourseApi';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { TokenStore } from '@lereacteur/apollo-common/dist/logic/TokenStore';
import { AtomMapSlice } from './AtomMapSlice';
import { CourseMapSlice } from './CourseMapSlice';
import { SessionMapSlice } from './SessionMapSlice';
import { MeAsUserSlice } from './MeAsUserSlice';
import { CoursesSlice } from './CoursesSlice';
import { UsersSlice } from './UsersSlice';
// import { BlueprintViewsSlice } from './BlueprintViewsSlice';
import { AsyncViewsSlice } from './AsyncViewsSlice';
import { SearchSlice } from './SearchSlice';
import { STUDENT_APP_ROUTES } from '@lereacteur/common/dist/constants/STUDENT_APP_ROUTES';
import { RoutePattern } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { SearchApi } from '@lereacteur/apollo-common/dist/api/SearchApi';
import { SessionTreeMapSlice } from './SessionTreeMapSlice';
import { useLocalStorageBoolean } from 'logic/hooks/useLocalStorageBoolean';
import { UserRole } from '@lereacteur/common/dist/constants/enums';

export interface AppSliceProps {
  tokenStore: TokenStore;
  stabilizer: (state: AppSliceState) => void;
  authApi: AuthApi;
  atomApi: AtomApi;
  userApi: UserApi;
  courseApi: CourseApi;
  sessionApi: SessionApi;
  searchApi: SearchApi;
  realtime: RealtimeClientManager;
}

export type AppSliceState = ReturnType<typeof AppSlice>;

export function AppSlice({
  tokenStore,
  stabilizer,
  authApi,
  atomApi,
  courseApi,
  sessionApi,
  userApi,
  searchApi,
  realtime,
}: AppSliceProps) {
  const { token, setToken, logout } = useChildren(createElement(TokenSlice, { tokenStore }));
  const router = useChildren(createElement(RouterSlice));
  const navigation = useChildren(createElement(NavigationSlice, { history: router.history }));
  const icons = useChildren(createElement(IconsSlice));
  const me = useChildren(createElement(MeResourceSlice, { authApi, token }));
  const meOrNull = me.dataOrNull;

  const atomMap = useChildren(createElement(AtomMapSlice, { atomApi, realtime }));
  const unsaved = useChildren(createElement(UnsavedSlice));
  const courseMap = useChildren(createElement(CourseMapSlice, { courseApi, realtime }));
  const sessionMap = useChildren(createElement(SessionMapSlice, { sessionApi, realtime }));
  const meAsUser = useChildren(
    meOrNull === null
      ? null
      : createElement(MeAsUserSlice, { userApi, realtime, userId: meOrNull.id })
  );
  const asyncViews = useChildren(createElement(AsyncViewsSlice, {}));

  const [adminMode, setAdminMode] = useLocalStorageBoolean('ADMIN_MODE', true);
  const isAdmin = me.dataOrNull?.role === UserRole.Values.admin;
  const adminModeResolved = adminMode && isAdmin;

  const [hideOldSessions, setHideOldSessions] = useLocalStorageBoolean('HIDE_OLD_SESSIONS', true);

  const sessionTreeMap = useChildren(
    createElement(SessionTreeMapSlice, { sessionApi, realtime, adminMode: adminModeResolved })
  );

  const coursesSelect = useChildren(createElement(CoursesSlice, { courseApi, realtime }));
  const usersSelect = useChildren(createElement(UsersSlice, { userApi, realtime }));

  const location = router.location;
  const initialLocation = router.initialLocation;

  const unsavedWarningVisible = useMemo(() => {
    return navigation.requested && unsaved.hasUnsaved;
  }, [navigation.requested, unsaved.hasUnsaved]);

  const allRoutesMatch = useMemo(() => {
    return RoutePattern.matchNestedRouteObject(STUDENT_APP_ROUTES, router.location.pathname);
  }, [router.location.pathname]);

  const setMeRequested = me.setRequested;

  useLayoutEffect(() => {
    setMeRequested(token !== null);
  }, [setMeRequested, token]);

  const courseHomeSessionId = allRoutesMatch.courseHome
    ? allRoutesMatch.courseHome.sessionId
    : null;
  const coursePageSessionId = allRoutesMatch.coursePage
    ? allRoutesMatch.coursePage.sessionId
    : null;

  const sessionId = courseHomeSessionId || coursePageSessionId;

  const search = useChildren(
    createElement(SearchSlice, { searchApi, sessionId, isAdminMode: adminModeResolved })
  );

  const state = useShallowMemo({
    token,
    setToken,
    logout,
    adminMode,
    setAdminMode,
    hideOldSessions,
    setHideOldSessions,
    navigation,
    location,
    initialLocation,
    icons,
    me,
    atomMap,
    courseMap,
    sessionMap,
    sessionTreeMap,
    meAsUser,
    coursesSelect,
    usersSelect,
    unsaved,
    unsavedWarningVisible,
    asyncViews,
    search,
  });

  useLayoutEffect(() => {
    stabilizer(state);
  });

  return state;
}

import React from 'react';
import { useCore } from 'logic/hooks/useCore';
import { Warning } from './Warning';

export const UnsavedWarning: React.FC = React.memo(() => {
  const { useSelector } = useCore();
  const show = useSelector.selectShowUnsavedWarning();

  if (!show) {
    return null;
  }

  return <Warning />;
});

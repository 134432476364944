import { createResourceMapSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from '../hooks/useLatestRef';
import { Session } from '@lereacteur/common/dist/routes/session';

interface Props {
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
}

export function SessionMapSlice({ sessionApi, realtime }: Props) {
  const sessions = useChildren(
    createResourceMapSlice<string, Session>({
      name: 'Session',
      fetchData: sessionApi.getById,
    })
  );

  const sessionsRef = useLatestRef(sessions);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.session) {
        const res = sessionsRef.current.resources.get(routes.session.id);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [sessionsRef, realtime]);

  return sessions;
}

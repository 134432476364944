import { useLayoutEffect, useCallback } from 'democrat';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from '../hooks/useLatestRef';
import { useResource } from '@lereacteur/apollo-common/dist/hooks/useResource';

interface Props {
  userApi: UserApi;
  realtime: RealtimeClientManager;
  userId: string;
}

export function MeAsUserSlice({ userApi, realtime, userId }: Props) {
  const fetchData = useCallback(() => userApi.getById(userId), [userApi, userId]);

  const users = useResource({
    name: 'MeAsUser',
    fetchData,
  });

  const usersRef = useLatestRef(users);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.user && routes.user.id === userId) {
        if (usersRef.current.requested) {
          usersRef.current.outdate();
        }
      }
    });
  }, [usersRef, realtime, userId]);

  return users;
}

import { FullscreenLoader } from '@lereacteur/apollo-common/dist/components/FullscreenLoader';
import { Envs } from 'logic/Envs';
import { useCore } from 'logic/hooks/useCore';
import React from 'react';

type Props = {
  content: React.ReactNode | null;
};

export const AppWrapper: React.FC<Props> = ({ content }) => {
  const { useSelector } = useCore();
  const meRes = useSelector.selectMeUser();
  const me = meRes.dataOrNull;
  if (me !== null || meRes.requested === false) {
    return <>{content}</>;
  }
  if (meRes.stable === false) {
    return <FullscreenLoader devInfo="Validating Token" isDev={Envs.IS_DEV} />;
  }
  return null;
};

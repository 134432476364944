import React from 'react';
import { Overlay } from '@lereacteur/apollo-common/dist/components/Overlay';
import { useCore } from 'logic/hooks/useCore';
import { Styled, Box, Flex } from '@lereacteur/apollo-common/dist/style';
import { Button } from '@lereacteur/apollo-common/dist/components/Button';

export const Warning: React.FC = React.memo(() => {
  const { useSelector } = useCore();
  const unsaved = useSelector.selectUnsaved();
  const cancelNav = useSelector.selectCancelNavigation();
  const ignoreUnsaved = useSelector.selectIgnoreUnsaved();

  return (
    <Overlay>
      <Styled.div
        zs={[
          Box.fill(),
          Flex.center(),
          {
            background: 'rgba(0, 0, 0, 0.8)',
          },
        ]}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            cancelNav();
          }
        }}
      >
        <Styled.div
          zs={[
            (theme) => ({
              background: theme.colors.white,
              borderRadius: theme.size.cardRadius,
              padding: theme.size.innerSpace,
            }),
          ]}
        >
          <Styled.h2
            zs={[
              {
                margin: 0,
                textAlign: 'center',
                marginBottom: '2rem',
              },
            ]}
          >
            You have unsaved work
          </Styled.h2>
          <div>
            <ul>
              {unsaved.map((item) => (
                <li key={item.id}>{item.message}</li>
              ))}
            </ul>
          </div>
          <Styled.div zs={[Flex.horizontal()]}>
            <Button onClick={cancelNav} style={{ marginRight: '1rem' }}>
              Cancel Navigation
            </Button>
            <Button onClick={ignoreUnsaved}>Ignore unsaved work</Button>
          </Styled.div>
        </Styled.div>
      </Styled.div>
    </Overlay>
  );
});

import { AppAsyncViewsType } from 'logic/async-views';
import { RoutePattern } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { STUDENT_APP_ROUTES } from '@lereacteur/common/dist/constants/STUDENT_APP_ROUTES';

type Route = () => JSX.Element;

export function getRoute(pathname: string, AsyncViews: AppAsyncViewsType) {
  return RoutePattern.routePatternSwitch<Route>(
    pathname,
    [
      RoutePattern.createRoute(STUDENT_APP_ROUTES.loginAs, () => () => <AsyncViews.LoginAs />),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.login, () => () => <AsyncViews.Login />),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.resetPassword, () => () => (
        <AsyncViews.PasswordReset />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.requestResetPassword, () => () => (
        <AsyncViews.RequestResetPassword />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.signupFromInvite, () => () => (
        <AsyncViews.SignupFromInvite />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.home, () => () => <AsyncViews.StudentHome />),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.coursePage, ({ sessionId, atomId }) => () => (
        <AsyncViews.StudentCoursePage sessionId={sessionId} atomId={atomId} />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.courseHome, ({ sessionId }) => () => (
        <AsyncViews.StudentCourseHome sessionId={sessionId} />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.studentProfile, () => () => (
        <AsyncViews.StudentProfileDetails />
      )),
      RoutePattern.createRoute(STUDENT_APP_ROUTES.studentProfileAuth, () => () => (
        <AsyncViews.StudentProfileLoginEdit />
      )),
    ],
    () => () => <AsyncViews.NotFound />
  );
}

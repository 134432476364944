import { useResourceWithParams } from '@lereacteur/apollo-common/dist/hooks/useResourceWithParams';
import { useLayoutEffect } from 'democrat';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import { UsersListResult, UsersListParams } from '@lereacteur/common/dist/routes/user';

interface Props {
  userApi: UserApi;
  realtime: RealtimeClientManager;
}

export function UsersSlice({ userApi, realtime }: Props) {
  const users = useResourceWithParams<UsersListResult, Required<UsersListParams>>({
    name: 'Users',
    fetchData: userApi.list,
    initialParams: {
      itemsPerPage: 10,
      pageIndex: 0,
      sort: null,
      order: null,
      sessions: null,
      search: null,
      role: null,
    },
  });

  const usersRef = useLatestRef(users);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.users) {
        if (usersRef.current.requested) {
          usersRef.current.outdate();
        }
      }
    });
  }, [usersRef, realtime]);

  return users;
}

import { Core } from './Core';
import { createSelectors } from './selectors';
import { version } from './version';

// creating core
export const core = Core({
  version,
  createSelectors,
});

export type AppCore = typeof core;

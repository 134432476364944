import { useResourceWithParams } from '@lereacteur/apollo-common/dist/hooks/useResourceWithParams';
import { useLayoutEffect } from 'democrat';
import { CourseApi } from '@lereacteur/apollo-common/dist/api/CourseApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import { CoursesListParams, CoursesListResult } from '@lereacteur/common/dist/routes/course';

interface Props {
  courseApi: CourseApi;
  realtime: RealtimeClientManager;
}

export function CoursesSlice({ courseApi, realtime }: Props) {
  const courses = useResourceWithParams<CoursesListResult, Required<CoursesListParams>>({
    name: 'Courses',
    fetchData: courseApi.list,
    initialParams: {
      itemsPerPage: 10,
      pageIndex: 0,
      sort: null,
      order: null,
      name: null,
      type: null,
      format: null,
      search: null,
    },
  });

  const coursesRef = useLatestRef(courses);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.courses) {
        if (coursesRef.current.requested) {
          coursesRef.current.outdate();
        }
      }
    });
  }, [coursesRef, realtime]);

  return courses;
}

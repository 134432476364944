import React from 'react';
import { setAutoFreeze, enableMapSet } from 'immer';
import { createStore, createElement, Store as DemocratStore } from 'democrat';
import { RealtimeClientManager } from './RealtimeClientManager';
import { ASYNC_VIEWS, AppAsyncViewsType } from 'logic/async-views';
import { AsyncViewLoader } from 'components/AsyncViewLoader';
import { Envs } from './Envs';
import { TokenStore } from '@lereacteur/apollo-common/dist/logic/TokenStore';
import { AuthApi } from '@lereacteur/apollo-common/dist/api/AuthApi';
import { Fetcher } from '@lereacteur/apollo-common/dist/api/utils/Fetcher';
import { AuthenticatedFetcher } from '@lereacteur/apollo-common/dist/api/utils/AuthenticatedFetcher';
import { AtomApi } from '@lereacteur/apollo-common/dist/api/AtomApi';
import { CourseApi } from '@lereacteur/apollo-common/dist/api/CourseApi';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { SearchApi } from '@lereacteur/apollo-common/dist/api/SearchApi';
import { AppSliceState, AppSlice } from './slices/AppSlice';
import { wait } from '@lereacteur/common/dist/utils/utils';
import { Selectors } from './selectors';
import { nni } from '@lereacteur/apollo-common/dist/logic/Invariant';
import { createStabilizer } from './stabilizer';
import { RandomDelay } from '@lereacteur/apollo-common/dist/logic/RandomDelay';
import {
  createSelectManager,
  SelectManager,
} from '@lereacteur/apollo-common/dist/connect/SelectManager';
import { createConnect } from '@lereacteur/apollo-common/dist/connect/Connect';

// Immer config: auto freeze raise error and make the app slow
setAutoFreeze(false);
enableMapSet();

// Config fake network delay
if (Envs.IS_DEV) {
  RandomDelay.turnOn();
  (window as any).RandomDelay = RandomDelay;
}

/**
 * Core is what glue together all the logic
 */

export type AppCore = ReturnType<typeof Core>;

interface CoreOptions {
  createSelectors: (selectManager: SelectManager) => Selectors;
  version: number;
}

export function Core(options: CoreOptions) {
  const { createSelectors, version } = options;

  // used to prevent multiple mount()
  let mounted: boolean = false;

  // Connect is responsible for connecting components to the store
  const connect = createConnect<AppSliceState>(Envs.IS_DEV);
  // SelectManager let you write selectors (similar to what redux has)
  // and deals with optimizing them (skip a selector if it's dependencies have not changed)
  const selectManager = createSelectManager();

  // we create our selectors, the createSelectors is passed as parameter
  const selectors = createSelectors(selectManager);
  // we create the useSelector hooks
  // every selector in the selectors object will become a hook
  // useSelector.mySelector(); <== this is a hook !
  const useSelector = connect.createUseSelector(selectors);

  const anonymousFetcher = Fetcher.create(version, Envs.REACT_APP_REACTEUR_API);

  const tokenStore = TokenStore();
  // the fetcher that require authent, it uses the authentStore to get the current authenticated user
  const authenticatedFetcher = AuthenticatedFetcher(
    version,
    Envs.REACT_APP_REACTEUR_API,
    tokenStore
  );

  const authApi = AuthApi(anonymousFetcher, authenticatedFetcher);

  // RealtimeClientManager is responsible for connecting to WebSocket
  // and dealing with messages from the server
  const realtime = RealtimeClientManager();

  // APIs
  const atomApi = AtomApi(authenticatedFetcher);
  const courseApi = CourseApi(authenticatedFetcher);
  const sessionApi = SessionApi(authenticatedFetcher);
  const userApi = UserApi(authenticatedFetcher);
  const searchApi = SearchApi(authenticatedFetcher);

  // AsyncViews
  // React components that are dynamically loaded
  // this create a Loader component for each view
  // this component is connected to the asyncViewsStore
  // to display a loader of the view
  const AsyncViews: AppAsyncViewsType = AsyncViewLoader.createFromObject(ASYNC_VIEWS);

  const stabilizerSelectCtx = selectManager.createContext('STABILIZER');

  // create the stabilizer
  // The stabilizer is executed after every dispatch
  const stabilizer = createStabilizer(stabilizerSelectCtx, () => core);

  // Listen for Outdate push messages
  // outdate resource by maching
  realtime.subscribeOutdate((routes) => {
    const state = getStore().getState();
    if (routes.user) {
      const me = state.me.dataOrNull;
      if (me && me.id === routes.user.id) {
        state.me.outdate();
      }
    }
    if (routes.atom) {
      const atomRes = state.atomMap.resources.get(routes.atom.id);
      if (atomRes) {
        atomRes.outdate();
      }
    }
  });

  let store: DemocratStore<AppSliceState> | null = null;

  async function mount(): Promise<void> {
    if (mounted) {
      throw new Error(`Already mounted`);
    }
    mounted = true;
    store = createStore(
      createElement(AppSlice, {
        tokenStore,
        stabilizer,
        authApi,
        atomApi,
        userApi,
        courseApi,
        sessionApi,
        searchApi,
        realtime,
      }),
      {
        ReactInstance: React,
      }
    );
    realtime.connect();
    return new Promise((resolve) => {
      wait(0).then(() => {
        const unsub = nni(store).subscribe(() => {
          unsub();
          wait(0).then(() => {
            resolve();
          });
        });
      });
    });
  }

  function getStore() {
    return nni(store);
  }

  const core = {
    getStore,
    mount,
    AsyncViews,
    selectManager,
    connect,
    useSelector,
    selectors,
    version,
    authApi,
    userApi,
    atomApi,
    sessionApi,
    courseApi,
    searchApi,
  };

  return core;
}

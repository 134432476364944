import { useCallback } from 'democrat';
import { SearchApi } from '@lereacteur/apollo-common/dist/api/SearchApi';
import { useResourceWithParams } from '@lereacteur/apollo-common/dist/hooks/useResourceWithParams';
import { Atom } from '@lereacteur/common/dist/routes/atom';

interface Props {
  searchApi: SearchApi;
  sessionId: string | null;
  isAdminMode: boolean;
}

export type SearchResult = {
  _id: string;
  title?: string;
  type: Atom['type'];
  score: number;
  session: string;
};

export function SearchSlice({ searchApi, sessionId, isAdminMode }: Props) {
  const doSearch = useCallback(
    async (query: string): Promise<Array<SearchResult>> => {
      if (query === '') {
        return [];
      }
      if (sessionId === null) {
        return searchApi.globalSearch(query, isAdminMode).then(
          (res): Array<SearchResult> => {
            if (res.length === 0) {
              return [];
            }
            // NOTE: We might want to present results by session...
            // For now we show averything by score
            const all = res
              .map((sessionResult) =>
                sessionResult.atoms.map(
                  (item): SearchResult => ({ ...item, session: sessionResult._id })
                )
              )
              .flat();
            return all.sort((l, r) => l.score - r.score);
          }
        );
      }
      return searchApi
        .sessionSearch(sessionId, query, isAdminMode)
        .then((res) => res.map((item): SearchResult => ({ ...item, session: sessionId })));
    },
    [searchApi, sessionId, isAdminMode]
  );

  const searchRes = useResourceWithParams({
    name: 'Search',
    initialRequested: false,
    fetchData: doSearch,
    initialParams: '',
  });

  return searchRes;
}

import React from 'react';
import { Subscription } from 'suub';

// This is ugly ! Please fix it !
const localStorageChanged = Subscription<void>();

export function useLocalStorageBoolean(
  key: string,
  initialState: boolean
): [boolean, (val: boolean) => void] {
  const forceRender = useForceRender();

  const stored = window.localStorage.getItem(key);
  const state: boolean =
    stored === null || stored === undefined ? initialState : JSON.parse(stored);

  React.useEffect(() => {
    const onStorage = () => {
      forceRender();
    };
    window.addEventListener('storage', onStorage);
    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [forceRender, key]);

  React.useEffect(() => {
    return localStorageChanged.subscribe(() => {
      forceRender();
    });
  }, [forceRender]);

  return [
    state,
    (val: boolean) => {
      window.localStorage.setItem(key, JSON.stringify(val));
      localStorageChanged.emit();
    },
  ];
}

function useForceRender(): () => void {
  const [, setState] = React.useState<number>(0);

  return React.useCallback(() => {
    setState(Math.random());
  }, []);
}

import { createResourceMapWithParamsSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapWithParamsSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { SessionTree, SessionTreeParams } from '@lereacteur/common/dist/routes/session';
import { useLatestRef } from 'logic/hooks/useLatestRef';

interface Props {
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
  adminMode: boolean;
}

export function SessionTreeMapSlice({ sessionApi, adminMode, realtime }: Props) {
  const sessionsTree = useChildren(
    createResourceMapWithParamsSlice<string, SessionTreeParams, SessionTree>({
      name: 'Session Tree',
      initialParams: { includeDrafts: adminMode, includeTrash: adminMode },
      fetchData: sessionApi.getTreeById,
    })
  );

  // sync adminMode
  useLayoutEffect(() => {
    sessionsTree.resources.forEach((res) => {
      if (res.params.includeDrafts !== adminMode || res.params.includeTrash !== adminMode) {
        res.setParams({ includeDrafts: adminMode, includeTrash: adminMode });
      }
    });
  }, [adminMode, sessionsTree.resources]);

  const sessionsTreeRef = useLatestRef(sessionsTree);

  useLayoutEffect(() => {
    return realtime.subscribe((message) => {
      if (message.type === 'OudateSessionTree') {
        const res = sessionsTreeRef.current.resources.get(message.sessionId);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [realtime, sessionsTreeRef]);

  return sessionsTree;
}

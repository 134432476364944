import { AsyncViewsType, ValidateAsyncImports } from 'components/AsyncViewLoader';

export const ASYNC_VIEWS = {
  AtomMarkdownRenderer: () =>
    import('views/AtomMarkdownRenderer').then((m) => m.AtomMarkdownRenderer),
  AtomRenderer: () => import('views/AtomRenderer').then((m) => m.AtomRenderer),
  EntityLayout: () => import('views/EntityLayout').then((m) => m.EntityLayout),
  Login: () => import('routes/Login').then((m) => m.Login),
  LoginAs: () => import('routes/LoginAs').then((m) => m.LoginAs),
  LoginLayout: () => import('views/LoginLayout').then((m) => m.LoginLayout),
  NotFound: () => import('routes/NotFound').then((m) => m.NotFound),
  PageHeader: () => import('views/PageHeader').then((m) => m.PageHeader),
  PasswordReset: () => import('routes/PasswordReset').then((m) => m.PasswordReset),
  RequestResetPassword: () =>
    import('routes/RequestResetPassword').then((m) => m.RequestResetPassword),
  SignupFromInvite: () => import('routes/SignupFromInvite').then((m) => m.SignupFromInvite),
  StudentCourseHome: () => import('routes/StudentCourseHome').then((m) => m.StudentCourseHome),
  StudentCoursePage: () => import('routes/StudentCoursePage').then((m) => m.StudentCoursePage),
  StudentHome: () => import('routes/StudentHome').then((m) => m.StudentHome),
  StudentLayout: () => import('views/StudentLayout').then((m) => m.StudentLayout),
  StudentProfile: () => import('views/StudentProfileLayout').then((m) => m.StudentProfileLayout),
  StudentProfileDetails: () => import('routes/StudentProfile').then((m) => m.StudentProfile),
  StudentProfileLoginEdit: () =>
    import('routes/StudentProfileAuth').then((m) => m.StudentProfileAuth),
} as const;

/**
 * Types (DON'T CHANGE IT !)
 */

export type AppAsyncViewsImports = typeof ASYNC_VIEWS;
export type AppAsyncViewName = keyof AppAsyncViewsImports;
export type AppAsyncViewsValue = {
  [K in AppAsyncViewName]: ReturnType<AppAsyncViewsImports[K]> extends Promise<infer R> ? R : any;
}[AppAsyncViewName];
// Unsed only to make sure ASYNC_VIEWS has valid type
export type Valid = ValidateAsyncImports<AppAsyncViewsImports>;
export type AppAsyncViewsType = AsyncViewsType<AppAsyncViewsImports>;
export type AppAsyncViewAny = AppAsyncViewsType[keyof AppAsyncViewsType];

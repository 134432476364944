import { Subscription } from 'suub';
import { Envs } from './Envs';
import { RealtimeDownMessage } from '@lereacteur/common/dist/Realtime';
import { RoutePattern, ExtractMatch } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { OUTDATE_TOPICS } from '@lereacteur/common/dist/constants/OUTDATE_TOPICS';

export type RealtimeClientManager = ReturnType<typeof RealtimeClientManager>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function RealtimeClientManager() {
  const oudateSub = Subscription<ExtractMatch<typeof OUTDATE_TOPICS>>();
  const messageSub = Subscription<RealtimeDownMessage>();

  let ws: WebSocket | null = null;

  function connect() {
    if (ws) {
      console.warn(`Already connected ?`);
      return;
    }

    const socket = new WebSocket(Envs.REACT_APP_PUSH_WEBSOCKET);
    socket.addEventListener('open', (_event) => {
      console.info(`Socket connected`);
      ws = socket;
    });

    socket.addEventListener('message', (event) => {
      try {
        if (typeof event.data === 'string' && event.data === 'ping') {
          return;
        }
        const message: RealtimeDownMessage = JSON.parse(event.data);
        if (message.type === 'OutdateResource') {
          const routes = RoutePattern.matchNestedRouteObject(OUTDATE_TOPICS, message.path);
          // small timer to prevent the outdate to be received before the response
          window.setTimeout(() => {
            oudateSub.emit(routes);
          }, 10);
          return;
        }
        if (message.type === 'Ping') {
          return;
        }
        messageSub.emit(message);
      } catch (error) {
        console.error(error);
      }
    });

    socket.addEventListener('close', () => {
      console.warn('Connection Closed');
      ws = null;
      window.setTimeout(() => {
        connect();
      }, 3000);
    });
  }

  function disconnect() {
    if (ws) {
      ws.close();
    }
  }

  return {
    subscribeOutdate: oudateSub.subscribe,
    subscribe: messageSub.subscribe,
    connect,
    disconnect,
  };
}

import { StyledTag } from 'zenstyle';

export const Svg = StyledTag.svg({
  margin: 0,
});

export const Wrapper = StyledTag.span({
  display: 'inline-block',
  lineHeight: '0',
  flex: 0,
  verticalAlign: 'text-bottom',
});

import queryString from 'query-string';
import { AppCore } from 'logic/Core';
import { ExtractMatch } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { AppSliceState } from 'logic/slices/AppSlice';
import { Resource, isResource } from '@lereacteur/apollo-common/dist/hooks/useResource';
import { STUDENT_APP_ROUTES } from '@lereacteur/common/dist/constants/STUDENT_APP_ROUTES';
import { UserRole } from '@lereacteur/common/dist/constants/enums';
import {
  AsyncViewState,
  AsyncViewStatus,
  isAsyncViewState,
  AsyncViewsStoreState,
} from 'logic/slices/AsyncViewsSlice';
import { SelectManagerContext } from '@lereacteur/apollo-common/dist/connect/SelectManager';
import { SessionTree, SessionTreeItem } from '@lereacteur/common/dist/routes/session';
import { Atom } from '@lereacteur/common/dist/routes/atom';
import { Me } from '@lereacteur/common/dist/routes/auth';

type Dependency = AsyncViewState | Resource<any>;
type Dependencies = Array<Dependency>;
export type DependenciesObj = { required: Dependencies; lazy: Dependencies };
export type DependenciesResult = Dependencies | DependenciesObj | null;

type Command =
  | { type: 'Dependencies'; deps: DependenciesObj }
  | { type: 'Redirect'; to: string; effect?: () => void }
  | null;

const Commands = {
  load(deps: DependenciesResult): Command {
    return { type: 'Dependencies', deps: cleanupDependenciesObject(deps) };
  },
  redirect(to: string, effect?: () => void): Command {
    return { type: 'Redirect', to, effect };
  },
};

export function createStabilizer(
  stabilizerSelectCtx: SelectManagerContext,
  getCore: () => AppCore
): (state: AppSliceState) => void {
  let prevResourcesDeps: Array<Resource<any>> = [];

  return (state) => {
    const { selectors } = getCore();

    const navigation = state.navigation;
    const currentRoutes = stabilizerSelectCtx.execute(selectors.selectAllRoutesMatch, state);

    const currentRouteCommand = stabilizeRoute(state, currentRoutes);
    if (currentRouteCommand === null) {
      // What ?
      return;
    }
    if (currentRouteCommand.type === 'Redirect') {
      if (currentRouteCommand.effect) {
        currentRouteCommand.effect();
      }
      navigation.redirect(currentRouteCommand.to);
      return;
    }
    const currentDeps = currentRouteCommand.deps;
    const requestedRoutes = stabilizerSelectCtx.execute(selectors.selectAllRoutesRequested, state);
    const requestedRouteCommand =
      requestedRoutes === null ? null : stabilizeRoute(state, requestedRoutes);
    if (requestedRouteCommand && requestedRouteCommand.type === 'Redirect') {
      if (requestedRouteCommand.effect) {
        requestedRouteCommand.effect();
      }
      // replace requested with redirect
      navigation.navigate(requestedRouteCommand.to);
    }
    const requestedDeps: DependenciesObj =
      requestedRouteCommand && requestedRouteCommand.type === 'Dependencies'
        ? requestedRouteCommand.deps
        : { required: [], lazy: [] };

    // combine all (lazy are only loaded for the current route)
    const nextDeps = [...currentDeps.required, ...currentDeps.lazy, ...requestedDeps.required];

    // find all async view and request them (no-op if already requested)
    const nextAsyncViewsDeps = nextDeps.filter(isAsyncViewState);
    nextAsyncViewsDeps.forEach((dep) => {
      state.asyncViews.request(dep.name);
    });

    // find all resources
    const nextResourcesDeps = nextDeps.filter(isResource);

    // flag resources we don't need anymore
    prevResourcesDeps.forEach((res) => {
      const stillInDeps = nextResourcesDeps.find((d) => d.sliceId === res.sliceId);
      if (!stillInDeps) {
        res.setRequested(false);
      }
    });

    // request new resources
    nextResourcesDeps.forEach((res) => {
      const alreadyInDeps = prevResourcesDeps.find((d) => d.sliceId === res.sliceId);
      if (!alreadyInDeps) {
        res.setRequested(true);
      }
    });

    // store resources for cleanup
    prevResourcesDeps = nextResourcesDeps;

    const currentAllResolved = currentDeps.required.every(dependencyResolved);

    // load icons when everything else is loaded !
    if (currentAllResolved && state.icons.requested === false) {
      state.icons.load();
    }

    // handle navigation requested
    if (state.navigation.requested) {
      if (state.unsavedWarningVisible) {
        // do not apply navigation
        return;
      }
      if (state.navigation.requestedTimeout) {
        navigation.applyRequestedLocation();
        return;
      }
      const nextRequiredAllResolved = requestedDeps.required.every(dependencyResolved);
      if (nextRequiredAllResolved) {
        navigation.applyRequestedLocation();
        return;
      }
    }
  };
}

function stabilizeRoute(
  state: AppSliceState,
  routes: ExtractMatch<typeof STUDENT_APP_ROUTES> | null
): Command {
  const views = state.asyncViews.state;

  if (routes === null) {
    return null;
  }

  // intercept the token in query and redirect to login
  if (routes.loginWithGithubToken) {
    return Commands.redirect(STUDENT_APP_ROUTES.login.serialize(), () => {
      const search = state.location.search;
      const params = queryString.parse(search);
      const token = params.token;

      if (token && typeof token === 'string') {
        state.setToken(token);
      }
    });
  }

  if (routes.login) {
    const loginViews = [views.LoginLayout, views.Login];
    if (state.token === null) {
      return Commands.load(loginViews);
    }
    const meStable = state.me.stable;
    if (meStable === false) {
      return Commands.load(loginViews);
    }
    const meUser = state.me.dataOrNull;
    if (meUser === null) {
      return Commands.load(loginViews);
    }
    const activated = meUser.activated !== false && meUser.sessions.length > 0;
    if (activated) {
      return Commands.redirect(STUDENT_APP_ROUTES.home.serialize());
    }
    return Commands.load(loginViews);
  }

  if (routes.signupFromInvite) {
    return unauthenticatedRoute(state, [views.LoginLayout, views.SignupFromInvite]);
  }

  if (routes.resetPassword) {
    return unauthenticatedRoute(state, [views.LoginLayout, views.PasswordReset]);
  }

  if (routes.requestResetPassword) {
    return unauthenticatedRoute(state, [views.LoginLayout, views.RequestResetPassword]);
  }

  if (routes.home) {
    return authenticatedRoute(state, [views.StudentHome, views.StudentLayout], (user) => {
      if (user.sessions.length === 1) {
        return Commands.redirect(
          STUDENT_APP_ROUTES.courseHome.serialize({ sessionId: user.sessions[0].id })
        );
      }
      return null;
    });
  }

  if (routes.loginAs) {
    return authenticatedAdminRoute(state, [views.StudentLayout, views.LoginAs]);
  }

  if (routes.courseHome) {
    const { sessionId } = routes.courseHome;
    return authenticatedRoute(state, [views.StudentLayout, views.StudentCourseHome], () => {
      const sessionRes = state.sessionMap.getOrVoid(sessionId);
      const sessionTreeRes = state.sessionTreeMap.getOrVoid(sessionId);
      const sessionTree = sessionTreeRes.dataOrNull;
      if (sessionTree) {
        const firstChild = sessionTree.children[0];
        if (firstChild) {
          return Commands.redirect(
            STUDENT_APP_ROUTES.coursePage.serialize({
              sessionId: sessionId,
              atomId: firstChild._id,
            })
          );
        }
      }
      return Commands.load([sessionRes, sessionTreeRes]);
    });
  }

  if (routes.coursePage) {
    const { sessionId, atomId } = routes.coursePage;
    return authenticatedRoute(
      state,
      [views.StudentLayout, views.StudentCoursePage, views.AtomRenderer],
      () => {
        const sessionRes = state.sessionMap.getOrVoid(sessionId);
        const sessionTreeRes = state.sessionTreeMap.getOrVoid(sessionId);

        const deps: Dependencies = [sessionRes, sessionTreeRes];
        const sessionTree = sessionTreeRes.dataOrNull;
        if (sessionTree) {
          // Make sure atom is in tree
          const sessionTreeItem = findInSessionTree(sessionTree, atomId);
          if (sessionTreeItem === null) {
            return Commands.redirect(STUDENT_APP_ROUTES.courseHome.serialize({ sessionId }));
          }
          const atomRes = state.atomMap.getOrVoid(atomId);
          deps.push(atomRes);
          const atom = atomRes.dataOrNull;
          if (atom) {
            if (atom.type === 'Folder') {
              const firstChild = sessionTreeItem.children[0];
              if (firstChild) {
                // redirect to first children
                return Commands.redirect(
                  STUDENT_APP_ROUTES.coursePage.serialize({
                    sessionId,
                    atomId: firstChild._id,
                  })
                );
              }
            }
            deps.push(...getAtomView(views, atom));
          }
        }
        return Commands.load(deps);
      }
    );
  }

  if (routes.studentProfile) {
    return authenticatedRoute(
      state,
      [
        views.StudentLayout,
        views.StudentProfileDetails,
        views.StudentProfile,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        if (state.meAsUser) {
          return Commands.load([state.meAsUser]);
        }
        return null;
      }
    );
  }

  if (routes.studentProfileAuth) {
    return authenticatedRoute(
      state,
      [
        views.StudentLayout,
        views.StudentProfileLoginEdit,
        views.StudentProfile,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        if (state.meAsUser) {
          return Commands.load([state.meAsUser]);
        }
        return null;
      }
    );
  }

  if (Array.from(Object.values(routes)).every((v) => v === false)) {
    return Commands.load([views.LoginLayout, views.NotFound]);
  }

  console.warn('Route not handled', routes);
  // return Commands.redirect(STUDENT_APP_ROUTES.home.serialize());
  return Commands.load([views.LoginLayout, views.NotFound]);
}

function unauthenticatedRoute(state: AppSliceState, views: DependenciesResult): Command {
  if (state.token !== null) {
    return Commands.redirect(STUDENT_APP_ROUTES.home.serialize());
  }
  return Commands.load(views);
}

function authenticatedRoute(
  state: AppSliceState,
  views: DependenciesResult,
  whenAuth?: (user: Me) => Command
): Command {
  if (state.token === null) {
    return Commands.redirect(STUDENT_APP_ROUTES.login.serialize());
  }
  const meUser = state.me.dataOrNull;
  const meStable = state.me.stable;

  if (meStable === false) {
    return Commands.load(views);
  }
  if (meUser === null) {
    // meUser failed => redirect to login
    return Commands.redirect(STUDENT_APP_ROUTES.login.serialize(), () => {
      state.logout();
    });
  }
  const activated = meUser.activated !== false && meUser.sessions.length > 0;
  if (!activated) {
    return Commands.redirect(STUDENT_APP_ROUTES.login.serialize());
  }
  const sub = whenAuth ? whenAuth(meUser) : null;
  if (sub === null) {
    return Commands.load(views);
  }
  if (sub.type === 'Redirect') {
    return sub;
  }
  return Commands.load(mergeDependenciesObj(sub.deps, cleanupDependenciesObject(views)));
}

function authenticatedAdminRoute(
  state: AppSliceState,
  views: DependenciesResult,
  whenAuth?: (user: Me) => Command
): Command {
  return authenticatedRoute(state, views, (user) => {
    if (user.role !== UserRole.Values.admin) {
      return Commands.redirect(STUDENT_APP_ROUTES.home.serialize());
    }
    return whenAuth ? whenAuth(user) : null;
  });
}

function dependencyResolved(dep: Dependency): boolean {
  if (isResource(dep)) {
    return dep.resource.status !== 'void' && dep.stable;
  }
  if (isAsyncViewState(dep)) {
    return dep.status === AsyncViewStatus.RESOLVED || dep.status === AsyncViewStatus.REJECTED;
  }
  console.warn('Unhandled dep', dep);
  return true;
}

function mergeDependenciesObj(left: DependenciesObj, right: DependenciesObj): DependenciesObj {
  return cleanupDependenciesObject({
    lazy: [...left.lazy, ...right.lazy],
    required: [...left.required, ...right.required],
  });
}

function cleanupDependenciesObject(deps: DependenciesResult): DependenciesObj {
  // Remove duplicate
  const deduped: DependenciesObj = { required: [], lazy: [] };
  const obj: DependenciesObj =
    deps === null
      ? { required: [], lazy: [] }
      : Array.isArray(deps)
      ? { required: deps, lazy: [] }
      : deps;
  const alreadyAddedKeys = new Set<string>();
  obj.required.forEach((dep) => {
    const key = getDependencyKey(dep);
    if (!alreadyAddedKeys.has(key)) {
      alreadyAddedKeys.add(key);
      deduped.required.push(dep);
    }
  });
  obj.lazy.forEach((dep) => {
    const key = getDependencyKey(dep);
    if (!alreadyAddedKeys.has(key)) {
      alreadyAddedKeys.add(key);
      deduped.lazy.push(dep);
    }
  });
  return deduped;
}

function getDependencyKey(dep: Dependency): string {
  if (isResource(dep)) {
    return dep.sliceId;
  }
  if (isAsyncViewState(dep)) {
    return `AsyncViewsSlice-${dep.name}`;
  }
  console.warn(dep);
  throw new Error(`Unhandled dep`);
}

function findInSessionTree(sessionTree: SessionTree, atomId: string): SessionTreeItem | null {
  if (sessionTree === null) {
    return null;
  }
  for (const child of sessionTree.children) {
    const item = findInSessionTreeItem(child, atomId);
    if (item) {
      return item;
    }
  }
  return null;
}

function findInSessionTreeItem(item: SessionTreeItem, atomId: string): SessionTreeItem | null {
  if (item._id === atomId) {
    return item;
  }
  for (const child of item.children) {
    const item = findInSessionTreeItem(child, atomId);
    if (item) {
      return item;
    }
  }
  return null;
}

function getAtomView(views: AsyncViewsStoreState, atom: Atom): Dependencies {
  if (atom.type === 'Root') {
    console.warn('Cannot render Root');
    return [];
  }
  if (atom.type === 'Folder') {
    return [];
  }
  if (atom.type === 'MarkdownFile') {
    return [views.AtomMarkdownRenderer];
  }
  if (atom.type === 'MarkdownFolder') {
    return [views.AtomMarkdownRenderer];
  }
  console.warn(`Unhandled atom type !`);
  return [];
}
